<template>
    <div class="deviceCash" :style="{minHeight:clientHeight}">
        <div v-if="deviceArr.length>0">
          <div class="card">
            <h5 class="deviceInfoTitle">设备信息</h5>
            <div class="deviceItem"  v-for="(item,index) in deviceArr" :key="index">
              <div> <span class="type">{{item.equipmentType}}</span><span class="cash"> 押金：{{item.equipmentAmt}}</span>
              </div>
              <p><label>设备编号：</label><span style="display:inline-block; margin-right: 0.5rem" v-for="(itemchild,i) in item.equipmentNo.split(',')" :key="i">{{itemchild}} </span></p>
            </div>
          </div>
            <!--押金付款记录-->
            <div v-if="paymentList.length != 0" class="payInfo" style="margin-top:0.3rem; font-size:0.26rem">
                <h5>押金付款记录</h5>
                <div v-for="(item, index) in paymentList" :key="index" style="margin-bottom: 0.3rem">
                    <div class="flex flexbetewwn">
                        <div>类型: {{item.paymentTypeDesc}}</div>
                    </div>
                    <div class="flex flexbetewwn">
                        <div>时间: {{item.paymentDate}}</div>
                    </div>
                        <div class="flex flexbetewwn">
                            <div>金额: {{item.paymentAmt}}</div>
                        </div>
                </div>
                <p class="payImgs" v-if="file1.length!=0 ">
                    <label>付款凭证</label><van-uploader v-model="file1" accept="image" :max-count="5" disabled  :deletable="false"/>
                </p>
                <p class="payImgs" v-if="file2.length!=0">
                    <label>付款授权委托书</label><van-uploader v-model="file2" accept="image" :max-count="5" disabled  :deletable="false"/>
                </p>
            </div>
            <div v-else >
                <div><p></p></div>
            </div>
          <!--如果待付款-->
          <div v-if="depositStatusCode==0||depositStatusCode==5">
              <div class="cashInfo">
                  <div class="cashExplain">
                    <label>站点设备押金</label>
                    <span @click="$router.push('/cashExplain')">设备押金说明</span>
                  </div>
                  <div>
                    <p class="cashAccount"><label>押金应付金额</label> <span>{{cashInfo.depositAmt}}元</span></p>
                    <div class="account">请将设备押金汇款至该账号：</div>
                    <div class="bgDiv">
                      <p>公司名称:{{cashInfo.companyName}}</p>
                      <p>银行账号:{{cashInfo.bankAccount}}</p>
                      <p>开户银行:{{cashInfo.bankName}}</p>
                    </div>
                  </div>
                  <div class="tip">可使用手机银行、柜台转账、线上付款等形式支付到公司指定账户，需备注设备押金及站点名称、站长姓名</div>
              </div>
              <div class="payImgs">
                  <label>付款凭证 </label>
                  <van-uploader v-model="fileList"  accept="image" :max-count="1"
                                :after-read="afterRead" :before-delete="deletePic" />
                  <p>付款成功后请上传付款凭证并确认完成押金付款</p>
              </div>

              <div class="payImgs2">
                  <label>付款授权委托书</label>
                  <van-uploader v-model="fileList2"  accept="image" :max-count="1"
                                :after-read="afterRead2" :before-delete="deletePic2" />
                  <p>如非站长本人付款 ，另需上传本委托书</p>
              </div>
              <div class="btnDiv">
                <div class="cancelBtn" @click="cancelAction">取消</div>
                <div class="commitBtn" @click="commitAction">确定完成</div>
              </div>
          </div>
            <div v-else >
                <div><p></p></div>
            </div>


        </div>

        <div v-else class="kong">
          <div><img :src="kongImg "><p>暂无设备信息</p></div>
        </div>

        <div v-if="reasonList.length != 0" class="payInfo" style="margin-top:0.3rem; font-size:0.26rem">
          <h5>押金调整记录</h5>
          <div v-for="(item, index) in reasonList" :key="index" style="margin-bottom: 0.3rem">
            <div v-for="(m, n) in item.equipmentInfoLog" :key="n" style="margin-bottom:0.1rem">
              <div>{{m.equipmentType}}</div>
              <div class="flex flexbetewwn">
                <div>时间: {{m.date}}</div>
                <div>具体原因：{{m.reason}}</div>
              </div>
              <div class="flex flexbetewwn">
                <div>调整前押金：{{m.equipmentAmt}}</div>
                <div>调整后押金：{{m.updAmt}}</div>
              </div>
            </div>
            <div>备注： {{item.remark}}</div>
          </div>
        </div>

    </div>

</template>

<script>
    import common from "../../utils/common";
    import JsZip from "jszip";
    import {getEquipmentDeposit, saveDepositInfo, uploadZipAndAlreadyImg} from "../../utils/api";
    import {appAction, uwStatisticAction} from "../../utils/util";
    import {PREVIOUS_PAGE} from "../../utils/constantNum";
    import kongImg from '../../assets/img/mine/deviceKong.png'

    export default {
      name: "deviceCash",
      data(){
        return {
          deviceArr:[],
          cashInfo:{},
          payImgs:[],
          fileList:[],
          fileList2:[],
            file1:[],
            file2:[],
          uploadZipImgList:[],
          uploadZipImgList2:[],
          fileImgUrls:[],
          fileImgUrls2:[],
          clientHeight:'667px',
          depositStatusCode:'',
          kongImg,
          reasonList:[],
          paymentList:[]
        }
      },
      mounted() {
        this.clientHeight = (document.body.clientHeight)+'px'
        this.getDeviceData()
        uwStatisticAction('/deviceCash','设备押金')
      },
        methods:{
            getDeviceData(){
              getEquipmentDeposit({}).then(res=>{
                  this.depositStatusCode=res.data.data.depositStatusCode
                if (res.data.data.equipmentInfo) {
                  this.deviceArr = res.data.data.equipmentInfo
                }
                if (res.data.data.depositAccount){
                  this.cashInfo = res.data.data.depositAccount
                }
                  if (res.data.data.paymentList){
                      this.paymentList = res.data.data.paymentList
                      console.log("paymentList",this.paymentList)
                      var baseUrl = process.env.NODE_ENV != 'production' ? 'http://124.70.167.116:20081' + '/file/' : window.location.origin + '/file/'
                      var tokenUrl = '?Authorization=' + common.getItem('wtToken')

                      for(var i = 0;i < this.paymentList.length;i++){
                          var thisItem = this.paymentList[i];
                          console.log("thisItem",thisItem)
                          console.log("paymentFiles",thisItem.paymentFiles)
                          if(thisItem.paymentFiles!='[]') {
                              var tempFile = JSON.parse(thisItem.paymentFiles);
                              console.log("tempFile", tempFile)
                              for (var j = 0; j < tempFile.length; j++) {
                                  var paymentFile = tempFile[j];
                                  console.log("paymentFile", paymentFile)
                                  this.file1.push({
                                      'url': baseUrl + paymentFile + tokenUrl
                                  })
                              }
                          }
                          if(thisItem.debitAuthorization!='[]') {
                              var debitFile = JSON.parse(thisItem.debitAuthorization);
                              console.log("file1", this.file1)
                              for (var j = 0; j < debitFile.length; j++) {
                                  var paymentFile = debitFile[j];
                                  this.file2.push({
                                      'url': baseUrl + paymentFile + tokenUrl
                                  })
                              }
                          }
                          console.log("file2",this.file2)
                      }

                  }
                if(res.data.data.list){
                  this.reasonList = res.data.data.list
                   for(let obj of this.reasonList){
                    obj.equipmentInfoLog = JSON.parse(obj.equipmentInfoLog)
                  }
                }
              }).catch(()=>{
                  // this.$toast('暂无设备信息')
              })
            },
            handlePic(imgUrlArr, type){
                console.log('imgArr', imgUrlArr)
              let url ='' ;
              for(let i= 0; i< imgUrlArr.length; i++){
                if(process.env.NODE_ENV === 'production'){
                   url = window.location.origin + '/file/' + imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')
                }else{
                  url = 'http://124.70.167.116:20081/file/' +  imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')
                }
              }
                if(type==='paymentFiles'){
                    this.fileList = [];
                    this.fileList.push({url:url});
                }else if (type==='debitAuthorization'){
                    this.fileList2 = [];
                    this.fileList2.push({url:url});
                }
            },
            afterRead (file, detail) {
                // 大于15M
                if (file.file.size > 20 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于20M的图片')
                    return
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 500 * 1024) {

                    let namearr = file.file.name.split('.')

                    let name = '.' + namearr[namearr.length - 1]

                    _this.uploadZipImgList.push({ data: file.content, name: name })
                    return
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)

                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {

                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList.push({ data: dataURL, name: name })

                        })
                    }
                }
            },
            deletePic (file, pic) {
              this.fileList.splice(pic.index,1)
              if (file.url){
                this.fileImgUrls.splice(pic.index,1)
              }else {
                this.uploadZipImgList.splice(pic.index - this.fileImgUrls.length,1)
              }
            },
            commitAction(){
                let self = this
                if (this.uploadZipImgList.length<1){
                    self.$toast('请上传付款凭证')
                    return
                }
                let imgName = 'cashPayImg', img2Name = 'powerOfAttorney'
                var zip = new JsZip();
                var img = zip.folder("images");
                for (var i = 0; i < this.uploadZipImgList.length; i++) {
                    img.file(imgName + '_' + i + this.uploadZipImgList[i].name,
                        this.uploadZipImgList[i].data.substring(this.uploadZipImgList[i].data.indexOf(",") + 1), {base64: true});
                }
                for (var j = 0; j < this.uploadZipImgList2.length; j++) {
                    img.file(img2Name + '_' + j + this.uploadZipImgList2[j].name,
                        this.uploadZipImgList2[j].data.substring(this.uploadZipImgList2[j].data.indexOf(",") + 1), {base64: true});
                }
                let json = {}
                json[imgName] = self.fileImgUrls
                json[img2Name] = self.fileImgUrls2
                let fileAleradyJson = JSON.stringify(json)


                zip.generateAsync({type: "blob"}).then(function (content) {
                    uploadZipAndAlreadyImg(content, '1', fileAleradyJson).then(res => {
                        if (res.code === 200 && (res.data.businessCode === 200)) {
                            console.log('压缩后的内容', res.data )

                            let arr = res.data[imgName]
                            let arr2 = res.data[img2Name]

                            self.fileImgUrls = arr
                            self.fileImgUrls2 = arr2
                            console.log("图片url：",self.fileImgUrls,self.fileImgUrls2)
                            self.saveAction()

                        }
                    }).catch(() => {
                    })

                }, e => {
                    this.$toast('上传图片异常')
                });
            },
            saveAction(){
                let p = {
                    paymentFiles : JSON.stringify(this.fileImgUrls),
                    debitAuthorization: JSON.stringify(this.fileImgUrls2),
                    payAmount:this.cashInfo.depositAmt,
                }
                saveDepositInfo(p).then(res=>{
                    if (res.code == 200 && (res.data.businessCode === 200)) {
                        this.$router.replace({
                            path:'/cashPaySuccess'
                        })
                    }

                })
            },
            cancelAction(){
                if (common.getItem('jumpFrom') != undefined ) {
                    appAction(PREVIOUS_PAGE)
                }else {
                    this.$router.push({name: 'mine'})
                }
            },
            afterRead2 (file, detail) {
                // 大于15M
                if (file.file.size > 20 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于20M的图片')
                    return;
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList2.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 500 * 1024) {
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    _this.uploadZipImgList2.push({ data: file.content, name: name })
                    return;
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)
                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {
                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList2.push({ data: dataURL, name: name })
                        })
                    }
                }
            },
            deletePic2 (file, pic) {
                this.fileList2.splice(pic.index,1)
                if (file.url){
                    this.fileImgUrls2.splice(pic.index,1)
                }else {
                    this.uploadZipImgList2.splice(pic.index - this.fileImgUrls2.length,1)
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .deviceCash{
      padding: 15px 15px 120px 15px;
      background: #F7F8F9;
      .deviceInfoTitle{
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1E1E1E;
        padding: 12px 0;
      }
      .flexbetewwn{
        justify-content: space-between;
      }
        .deviceItem{
          background: #ffffff;
          div{
            border-left: 4px solid #3F7C53 ;
            border-radius:3px ;
            padding: 12px 24px 12px 20px;
            border-bottom:1px solid #e8e8e8 ;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .type{

                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #272829;

            }
            .cash{

                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #6B6B6B;
            }


          }
            p{
                background: #ffffff;
                margin-bottom: 12px;
                padding: 13px 20px ;
                label{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #272829;
                    display: inline-block;
                    vertical-align: middle;
                }

                span{

                    font-size: 20px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #3F7C53;
                    display: inline-block;
                    vertical-align: middle;
                }

            }

        }
        .cashInfo{
            background: #ffffff;
            padding: 0 15px;
            .cashExplain{
                padding: 12px 9px;
                border-bottom: 1px solid #e8e8e8;
                display: flex;
                justify-content: space-between;
                align-items: center;
                label{
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #1E1E1E;
                }
                span{
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #3F7C53;
                }

            }
            .cashAccount{
                padding-top: 22px;
                padding-left: 9px;
                label{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9B9B9B;
                }
                span{
                    margin-left: 40px;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #272829;
                }

            }
            .account{
                padding-left: 9px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #272829;
            }
            .bgDiv{
                padding:11px  0 11px 14px ;
                background: #F8FAFD;
                border-radius: 8px;
                p{
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #272829;

                }
            }
            .tip{
                padding-left: 9px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9B9B9B;
                margin:  12px  0 0 0;
            }

        }
        .payImgs{
            padding: 22px 15px 20px 24px;
            background: #ffffff;
            label{
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9B9B9B;
                display: inline-block;
                vertical-align: top;
                margin-right: 55px;
            }
            p{
                padding-top: 8px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9B9B9B;
            }

        }
        .payImgs2{
            padding: 25px 15px 20px 24px;
            background: #ffffff;
            label{
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9B9B9B;
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;
            }
            p{
                padding-top: 8px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9B9B9B;
            }
        }
        .btnDiv{
            background: #ffffff;
            position: absolute;
            left: 0;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            padding:12px;
            width: 100%;
            div{
                width:calc(50% - 6px);
                text-align: center;
                padding: 12px 0;
            }
            .cancelBtn{

                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                background: #FFFFFF;
                border-radius: 4px;
                border: 1px solid #E5E5E5;
            }
            .commitBtn{

                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                background: #3F7C53;
                border-radius: 4px;
            }
        }
        .payInfo{
            background: #FFFFFF;
            padding: 12px 24px;
            h5{

                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #1E1E1E;
            }
            p{
                padding: 6px 0;
                margin: 0;
                letter-spacing: 1px;
                label{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9B9B9B;
                }
                span{
                    margin-left: 40px;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #272829;
                }

            }
            .payImgs{

            }
        }
        .kong{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 60px;
            img{
               width: 210px;
            }
            p{
                margin-top: 30px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #616161;
                text-align: center;
                letter-spacing: 1px;
            }

        }
    }

</style>
<style>
    .deviceCash  .van-uploader__wrapper--disabled{
        opacity: 1;
    }
</style>
